import * as yup from "yup";


const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const signupValidators = yup.object({
	firstname: yup.string().trim().required("Il campo 'nome' è obbligatorio").matches(/^[^\d]*$/, "Il campo 'nome' è in un formato non valido"),
	lastname: yup.string().trim().required("Il campo 'cognome' è obbligatorio").matches(/^[^\d]*$/, "Il campo 'cognome' è in un formato non valido"),
	email: yup
		.string()
		.trim()
		.email("Inserisci un indirizzo email valido")
		.matches(emailRegex, "Inserisci un indirizzo email valido")
		.required("Il campo 'email' è obbligatorio"),
	password: yup
		.string().trim("trim-string")
		.matches(/^(?=.{10,})/, "La password deve contenere almeno 10 caratteri")
		.matches(/[A-Z]/, "La password deve contenere almeno un carattere maiuscolo")
		.matches(/^(?=.*\d).+$/, "La password deve contenere almeno un numero")
		.required("Il campo 'password' è obbligatorio"),
	terms: yup.bool().oneOf([true], "Devi accettare i termini e le condizioni"),
	news_and_contact_partner: yup.bool(),
});

export const signupUnimarconiValidators = yup.object({
	firstname: yup.string().trim().required("Il campo 'nome' è obbligatorio"),
	lastname: yup.string().trim().required("Il campo 'cognome' è obbligatorio"),
	email: yup
		.string()
		.trim()
		.required("Il campo 'email' è obbligatorio"),
	password: yup
		.string().trim("trim-string")
		.matches(/^(?=.{10,})/, "La password deve contenere almeno 10 caratteri")
		.matches(/[A-Z]/, "La password deve contenere almeno un carattere maiuscolo")
		.matches(/^(?=.*\d).+$/, "La password deve contenere almeno un numero")
		.required("Il campo 'password' è obbligatorio"),
	phoneNumber: yup.object({
		prefix: yup
			.string()
			.trim()
			.max(3, "Il prefisso deve contenere al massimo 4 caratteri")
			.matches(/^\d+$/, "Il prefisso deve contenere solo numeri")
			.required("Il campo 'prefisso' è obbligatorio"),
		number: yup
			.string()
			.trim()
			.min(4, "Il numero deve contenere almeno 4 caratteri")
			.max(15, "Il numero deve contenere al massimo 15 caratteri")
			.matches(/^\d+$/, "Il numero di telefono deve contenere solo numeri")
			.required("Il campo 'numero' è obbligatorio")
	}),
	terms: yup.bool().oneOf([true], "Devi accettare i termini e le condizioni"),
	news_and_contact_partner: yup.bool(),
})
