/* eslint-disable sonarjs/pseudo-random */
import React, { createContext, ReactNode, useCallback, useContext, useMemo, useState } from "react";

export type ToastTypes = "primary" | "info" | "error" | "success" | "warning" | "waiting"

interface Toast {
	id: number;
	label: string;
	type: ToastTypes
}

interface ToastContextType {
	showToast: (label: string, type: ToastTypes) => void;
	hideToast: () => void;
	toast: Toast | null;
}

interface ToastProviderProps {
	children: ReactNode
}

const ToastContext = createContext<ToastContextType | null>(null);


const ToastProvider = ({ children }: ToastProviderProps) => {
	const [toast, setToast] = useState<Toast | null>(null);

	const showToast = useCallback((label: string, type: ToastTypes) => {
		const id = Math.floor(Math.random() * 100);
		setToast({ id, label, type });
	}, []);

	const hideToast = useCallback(() => {
		setToast(null);
	}, []);


	const memoedValues = useMemo(() => ({ showToast, hideToast, toast }), [showToast, hideToast, toast]);

	return (
		<ToastContext.Provider value={memoedValues}>
			{children}
		</ToastContext.Provider>
	);
};

const useToast = () => {
	const context = useContext(ToastContext);
	if (!context) {
		throw new Error("useToast must be used within a ToastProvider");
	}
	return context;
};


export { ToastProvider, useToast };
