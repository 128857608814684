/* eslint-disable max-len */
import React from "react";

type TextElement = "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "span" | "p";

export const typographyMap = {
	h1: { tag: "h1", classes: "text-h1-mobile sm:text-h1-desktop font-bold font-title sm:landing:text-h1-desktop-landing landing:text-h1-mobile-landing" },
	h2: { tag: "h2", classes: "text-h2-mobile sm:text-h2-desktop font-bold font-title sm:landing:text-h2-desktop-landing landing:text-h2-mobile-landing" },
	h3: { tag: "h3", classes: "text-h3-mobile sm:text-h3-desktop font-bold font-title sm:landing:text-h3-desktop-landing landing:text-h3-mobile-landing" },
	h4: { tag: "h4", classes: "text-h4-mobile sm:text-h4-desktop font-bold font-title sm:landing:text-h4-desktop-landing landing:text-h4-mobile-landing" },
	h5: { tag: "h5", classes: "text-h5-mobile sm:text-h5-desktop font-bold font-title sm:landing:text-h5-desktop-landing landing:text-h5-mobile-landing" },
	h6: { tag: "h6", classes: "text-h6-mobile sm:text-h6-desktop font-bold font-title sm:landing:text-h6-desktop-landing landing:text-h6-mobile-landing" },
	label: { tag: "p", classes: "text-label-mobile sm:text-label-desktop font-medium font-paragraph sm:landing:text-label-desktop-landing landing:text-label-mobile-landing" },
	"label-1": { tag: "p", classes: "text-label-1-mobile sm:text-label-1-desktop font-medium font-paragraph sm:landing:text-label-1-desktop-landing landing:text-label-1-mobile-landing" },
	"label-2": { tag: "p", classes: "text-label-2-mobile sm:text-label-2-desktop font-medium font-paragraph sm:landing:text-label-2-desktop-landing landing:text-label-2-mobile-landing" },
	"label-3": { tag: "p", classes: "text-label-3-mobile sm:text-label-3-desktop font-medium font-paragraph sm:landing:text-label-3-desktop-landing landing:text-label-3-mobile-landing" },
	"label-4": { tag: "p", classes: "text-label-4-mobile sm:text-label-4-desktop font-medium font-paragraph sm:landing:text-label-4-desktop-landing landing:text-label-4-mobile-landing" },
	"paragraph-p1": { tag: "p", classes: "text-paragraph-p1-mobile sm:text-paragraph-p1-desktop font-normal font-paragraph sm:landing:text-paragraph-p1-desktop-landing landing:text-paragraph-p1-mobile-landing" },
	"paragraph-span1": { tag: "p", classes: "text-paragraph-span1-mobile sm:text-paragraph-span1-desktop font-normal font-paragraph sm:landing:text-paragraph-span1-desktop-landing landing:text-paragraph-span1-mobile-landing" },
	"paragraph-span2": { tag: "p", classes: "text-paragraph-span2-mobile sm:!text-paragraph-span2-desktop font-normal font-paragraph sm:landing:text-paragraph-span2-desktop-landing landing:text-paragraph-span2-mobile-landing" },
	"paragraph-span3": { tag: "p", classes: "text-paragraph-span3-mobile sm:text-paragraph-span3-desktop font-normal font-paragraph sm:landing:text-paragraph-span3-desktop-landing landing:text-paragraph-span3-mobile-landing" },
	"paragraph-span4": { tag: "p", classes: "text-paragraph-span4-mobile sm:text-paragraph-span4-desktop font-normal font-paragraph sm:landing:text-paragraph-span4-desktop-landing landing:text-paragraph-span4-mobile-landing" },
	"button-1": { tag: "p", classes: "text-button-1-mobile sm:text-button-1-desktop font-bold font-paragraph sm:landing:text-button-1-desktop-landing landing:text-button-1-mobile-landing" },
	"button-2": { tag: "p", classes: "text-button-2-mobile sm:text-button-2-desktop font-bold font-paragraph sm:landing:text-button-2-desktop-landing landing:text-button-2-mobile-landing" },
	"button-3": { tag: "p", classes: "text-button-3-mobile sm:text-button-3-desktop font-bold font-paragraph sm:landing:text-button-3-desktop-landing landing:text-button-3-mobile-landing" },
	"button-4": { tag: "p", classes: "text-button-4-mobile sm:text-button-4-desktop font-bold font-paragraph sm:landing:text-button-4-desktop-landing landing:text-button-4-mobile-landing" },
};


interface TypographyProps extends React.HTMLAttributes<HTMLElement> {
	variant: keyof typeof typographyMap;
	children: React.ReactNode;
	component?: TextElement
}

const Typography: React.FC<TypographyProps> = ({ component, variant, className, children, ...restProps }) => {
	const Tag = component || typographyMap[variant].tag as TextElement;
	const classes = `${typographyMap[variant].classes} ${className || ""}`;
	return <Tag className={classes} {...restProps}>{children}</Tag>;
};

export default Typography;
