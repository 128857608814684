/**
 * Custom hook to fetch plan information based on URL query parameters and reCAPTCHA verification.
 *
 * @returns {Object} An object containing the plan information, loading state, and error state.
 * @property {any} planInfo - The fetched plan information.
 * @property {boolean} planInfoLoading - Indicates if the plan information is currently being loaded.
 * @property {Error | null} planInfoError - The error object if an error occurred while fetching the plan information.
 *
 * @example
 * const { planInfo, planInfoLoading, planInfoError } = usePlanInfo();
 */

import { useEffect, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import { fetchRegistrationPlanInfo } from "../../api/queries";


const usePlanInfo = () => {
	const { executeRecaptcha } = useGoogleReCaptcha();
	const { search } = useLocation();
	const [recaptcha, setRecaptcha] = useState("");
	const [isFetchingRecaptcha, setIsFetchingRecaptcha] = useState(true);

	const searchParams = new URLSearchParams(search);
	const queryParamsSlug = searchParams.get("plan");


	const { data: planInfo, isLoading: planInfoLoading, error: planInfoError } = useQuery({
		queryKey: ["plans", queryParamsSlug, recaptcha],
		queryFn: () => fetchRegistrationPlanInfo(queryParamsSlug, recaptcha),
		enabled: !!recaptcha
	});


	useEffect(() => {
		if (executeRecaptcha && !recaptcha) {
			const getRecaptcha = async () => {
				const token = await executeRecaptcha("plan_info");
				setRecaptcha(token);
				setIsFetchingRecaptcha(false);
			};

			getRecaptcha();
		}
	}, [executeRecaptcha, recaptcha]);

	return { planInfo, planInfoError, planInfoLoading: isFetchingRecaptcha || planInfoLoading };
};

export default usePlanInfo;
