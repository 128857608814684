import { checkCookie } from "@frontend-monorepo/helpers";

import config from "../config";

const redirectByRoleOnLogin = (cookieName: string, loginProperties: string[]) => {
	const { user } = checkCookie(cookieName) || {};

	if (!user) {
		return;
	}

	if (user.role.toLowerCase() === "admin") {
		window.location.href = config.adminUrl;
	}

	else if (user.role.toLowerCase() === "coach") {
		window.location.href = config.coachUrl;
	}
	if (user.role.toLowerCase() === "student") {
		if (loginProperties.includes("login_redirect_master")) {
			window.location.href = config.masterUrl;
		}
		else {
			window.location.href = config.talentUrl;
		}
	}
};

export default redirectByRoleOnLogin;
