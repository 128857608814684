/* eslint-disable tailwindcss/no-custom-classname */
/* eslint-disable sonarjs/no-nested-conditional */
/* eslint-disable react/display-name */
import React, { useState } from "react";
import { faEye, faEyeSlash } from "@awesome.me/kit-ff2ccff083/icons/classic/solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";

import { Spinner } from "../../spinner";
import { Typography } from "../../typography";

/**
 * Props for the TextInput component.
 */
export interface TextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
	/** The unique id for the input element */
	id: string;
	/** The name attribute for the input element */
	name: string;
	/** Indicates if the input is in an invalid state */
	isInvalid?: boolean;
	/** The error message to display when the input is invalid */
	errorMessage?: string;
	/** The label for the input element */
	label?: string;
	/** Indicates if a button to show/hide the password should be displayed */
	showPasswordButton?: boolean;
	/** Indicates if the input is in a loading state */
	isLoading?: boolean;
	/** An optional icon to display within the input element */
	icon?: React.ReactNode;
}

/**
 * Base text input component.
 */
const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
	({ icon, id, name, isInvalid, errorMessage, label, type, disabled, className, showPasswordButton = true, isLoading = false, ...restProps }, ref) => {
		const [showPassword, setShowPassword] = useState(false);
		const toggleShowPassword = () => setShowPassword(!showPassword);


		return (
			<div className="flex flex-col">
				{label && (
					<label htmlFor={id}>
						<Typography variant="paragraph-span3">{label}</Typography>
					</label>
				)}
				<div className="relative mt-2">
					<input
						ref={ref}
						type={type === "password" && !showPassword ? "password" : showPassword ? "text" : type}
						id={id}
						name={name}
						disabled={disabled || isLoading}
						className={clsx(
							"w-full rounded-lg p-4 text-left text-paragraph-span2-mobile text-greyscale-dark sm:!text-paragraph-span2-desktop",
							"border-0 shadow-input-inner-border placeholder:text-greyscale-mid/50",
							"focus:ring-transparent focus:ring-0 focus-visible:shadow-input-inner-focus focus-visible:outline-none",
							"disabled:bg-greyscale-extralight disabled:text-greyscale-mid disabled:shadow-input-inner-disabled",
							className,
						)}
						{...restProps}
					/>
					{isLoading && type !== "date" && (
						<Spinner className="absolute right-4 top-1/2 -translate-y-1/2" />
					)}

					{type === "password" && showPasswordButton && !isLoading && (
						<button
							className="absolute right-4 top-1/2 -translate-y-1/2 text-greyscale-mid opacity-50"
							type="button"
							onClick={toggleShowPassword}
						>
							{showPassword ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
						</button>
					)}

					{icon && type !== "date" && type !== "password" && (
						<span className="absolute right-0 top-1/2 flex h-full -translate-y-1/2 items-center justify-center p-4">{icon}</span>
					)}
				</div>
				{isInvalid && <Typography className="mt-2 block text-feedback-error" variant="paragraph-span3">{errorMessage}</Typography>}
			</div>
		);
	},
);

export default TextInput;
