import { faFacebookSquare, faInstagram, faYoutube } from "@awesome.me/kit-ff2ccff083/icons/classic/brands";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Typography } from "../typography";

export interface FooterProps {
	openCustomerlyLiveChat?: () => void;
}


const Footer = ({ openCustomerlyLiveChat }: FooterProps) => {
	return (
		<footer className="bg-greyscale-dark" data-testid="footer">
			<div className="container mx-auto justify-between px-6 py-8 lg:flex">
				<div>
					<div className="flex flex-col md:flex-row md:items-center">
						<Typography variant="label-2" className="text-greyscale-base">
							© 2017 -
							{" "}
							{new Date().getFullYear()}
							{" "}
							<span className="hidden md:inline-block">-&nbsp;</span>
						</Typography>
						<Typography variant="label-2" className="text-greyscale-base">
							start2impact srl Società Benefit
							{" "}
							<span className="hidden md:inline-block">|&nbsp;</span>
						</Typography>
						<Typography variant="label-2" className="text-greyscale-base">
							PIVA 10104990964
						</Typography>

					</div>


					<div className="mt-2 flex flex-wrap items-center gap-x-1 md:mt-1">
						<a
							href="https://www.start2impact.it/termini-e-condizioni/"
							target="_blank"
							rel="noreferrer"
							className="text-brand-light underline hover:font-medium"
						>
							<Typography className="hover:font-semibold" variant="paragraph-span2">
								Termini
							</Typography>
						</a>
						<Typography variant="paragraph-span2" className="text-greyscale-base">
							|
						</Typography>
						<a href="https://www.start2impact.it/privacy-policy/" className="text-brand-light underline hover:font-medium" target="_blank" rel="noreferrer">
							<Typography className="hover:font-semibold" variant="paragraph-span2">Privacy</Typography>
						</a>
						<Typography variant="paragraph-span2" className="text-greyscale-base">
							|
						</Typography>
						{openCustomerlyLiveChat ? (
							<button type="button" onClick={openCustomerlyLiveChat} className="text-brand-light underline hover:font-medium">
								<Typography className="hover:font-semibold" variant="paragraph-span2">Live Chat</Typography>
							</button>
						) : (
							<a href="https://start2impact.customerly.help/chat-with-us" className="text-brand-light underline hover:font-medium" target="_blank" rel="noreferrer">
								<Typography className="hover:font-semibold" variant="paragraph-span2">Live Chat</Typography>
							</a>
						)}

						<Typography variant="paragraph-span2" className="text-greyscale-base">
							|
						</Typography>
						<a href="https://www.start2impact.it/blog-page/" className="text-brand-light underline hover:font-medium" target="_blank" rel="noreferrer">
							<Typography className="hover:font-semibold" variant="paragraph-span2">Blog</Typography>
						</a>
						<Typography variant="paragraph-span2" className="text-greyscale-base">
							|
						</Typography>
						<a href="https://start2impact.customerly.help/" className="text-brand-light underline hover:font-medium" target="_blank" rel="noreferrer">
							<Typography className="hover:font-semibold" variant="paragraph-span2">Help Center</Typography>
						</a>
					</div>
				</div>

				<div className="mt-8 flex items-center gap-x-8 text-greyscale-base md:justify-end lg:mt-0">
					<a
						className="inline-block  hover:text-brand-light"
						href="https://www.instagram.com/start2impact/"
					>
						<span className="sr-only">Pagina Instagram</span>
						<FontAwesomeIcon icon={faInstagram} className="size-8" />
					</a>
					<a
						className="hover:text-brand-light"
						href="https://it-it.facebook.com/start2impact/"
					>
						<span className="sr-only">Pagina FaceBook</span>
						<FontAwesomeIcon icon={faFacebookSquare} className="size-8" />
					</a>
					<a
						className=" hover:text-brand-light"
						href="https://www.youtube.com/channel/UC3pEI-6M5tlZe1sSrkERSww"
					>
						<span className="sr-only">Canale YouTube</span>
						<FontAwesomeIcon icon={faYoutube} className="size-8" />
					</a>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
