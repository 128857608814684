import ReactDOM from "react-dom/client";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { CustomerlyProvider } from "react-live-chat-customerly";
import { ToastProvider } from "@frontend-monorepo/design-system";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import App from "./App";

import config from "./config";

import "./index.css";

const queryClient = new QueryClient();

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
	<CustomerlyProvider appId="d915533c">
		<GoogleReCaptchaProvider reCaptchaKey={config.reCaptchaKey} language="it">
			<QueryClientProvider client={queryClient}>
				<ToastProvider>
					<App />
				</ToastProvider>
				<ReactQueryDevtools initialIsOpen={false} />
			</QueryClientProvider>
		</GoogleReCaptchaProvider>
	</CustomerlyProvider>,
);
