import { http } from "@frontend-monorepo/helpers";

import { Nullable } from "../../types/utils";

import { FetchRegistrationPlanInfoResponse, FetchRegistrationUnimarconiInfoResponse } from "../../types/api";

import config from "../../config";


const fetchRegistrationPlanInfo = async (plan_slug: string | null, recaptcha: string): Promise<FetchRegistrationPlanInfoResponse> => {
	const hasSlug = plan_slug ? `?plan_slug=${plan_slug}` : "";
	const response = await http(config.cookieName, config.loginUrl, false)
		.get(`${config.apiUrl}/public/users/registration${hasSlug}`, { headers: { "X-Recaptcha-Token": recaptcha } });

	return response.data.entities.plan.item;
};

const fetchRegistrationUnimarconiInfo = async (token: Nullable<string>): Promise<FetchRegistrationUnimarconiInfoResponse> => {
	if (!token) throw new Error()
	const response = await http(config.cookieName, config.loginUrl, false).get(`${config.apiUrl}/v2/auth/master/user-info?token=${token}`);

	return response.data;
}

const fetchTopics = async () => {
	const response = await http(config.cookieName, config.loginUrl, false).get(`${config.apiUrl}/public/topics`);

	return response.data;
};

export { fetchRegistrationPlanInfo, fetchTopics, fetchRegistrationUnimarconiInfo }
