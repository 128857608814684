import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { Loader, Typography } from "@frontend-monorepo/design-system";
import { useQuery } from "@tanstack/react-query";

import SignupForm from "../form";
import ErrorPage from "./error-page";

import { fetchRegistrationUnimarconiInfo } from "../../../api/queries";

import config from "../../../config";


const Signup = () => {

	// get token from url (search param)
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);
	const token = searchParams.get("token");

	const { data, isLoading, isError } = useQuery({
		queryKey: ["unimarconi", token],
		queryFn: () => fetchRegistrationUnimarconiInfo(token),
	})

	if (isLoading || (!data && !isError)) return <Loader />

	if (isError) return <ErrorPage />

	return (
		<>
			<Helmet>
				<title>Registrazione | start2impact</title>
			</Helmet>

			<main className="min-h-screen bg-ui-secondary bg-contain bg-bottom bg-no-repeat" style={{ backgroundImage: `url(${config.assetsPath}images/bg-wave-green.svg)` }}>
				<div className="flex h-20 items-center justify-center bg-greyscale-dark">
					<img
						src="https://res.cloudinary.com/start2impact/image/upload/v1720185442/logo/logowhite.png"
						alt=""
						width="220"
						height="48"
						className="h-8 w-40 sm:h-12 sm:w-56"
					/>
					<img
						src="https://res.cloudinary.com/start2impact/image/upload/v1737553369/Piattaforma/unimarconi/Unimarconi_white.svg"
						alt=""
						width="220"
						height="40"
						className="h-8 w-40 sm:h-10 sm:w-56"
					/>
				</div>

				<section className="mx-auto flex flex-col px-4 pb-20 pt-10 sm:pt-20 md:!px-0">
					<h1 className="sr-only">Pagina di registrazione</h1>
					<Typography variant="h2" className="mb-10 text-center font-bold">Completa la registrazione e accedi al Master</Typography>

					<SignupForm
						isMaster={true}
						token={token}
						initialValues={{
							email: data.email,
							firstname: data.firstname,
							lastname: data.lastname,
							phoneNumber: {
								prefix: "39",
								number: ""
							},
							terms: false,
							news_and_contact_partner: false,
							password: "",
						}} />
				</section>
			</main>
		</>
	);
};

export default Signup;
