import React, { ReactNode } from "react";
import clsx from "clsx";

import { Typography } from "../../typography";

export interface CheckboxProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "size"> {
	id: string
	name: string
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
	label?: ReactNode
	size?: "lg" | "md"
	labelClassName?: string
}

const Checkbox = ({ className, id, name, label, labelClassName, onChange, size = "lg", ...restProps }: CheckboxProps) => {
	return (
		<label htmlFor={name} className="flex items-center gap-x-2">
			<input
				onChange={onChange}
				type="checkbox"
				id={id}
				name={name}
				className={clsx(
					"cursor-pointer border-2 border-brand-secondary-light text-brand-mid",
					"focus:border-brand-primary focus:ring-0 focus:ring-[transparent] focus-visible:outline-none",
					size === "md" ? "size-3 rounded-sm border" : "size-5 rounded border-2",
					className,
				)}
				{...restProps}
			/>
			{label && (
				<Typography variant={size === "md" ? "paragraph-span4" : "paragraph-span2"} className={clsx("cursor-pointer", labelClassName)}>
					{label}
				</Typography>
			)}
		</label>
	);
};

export default Checkbox;
