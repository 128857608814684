import { Footer, Typography } from "@frontend-monorepo/design-system"

import config from "../../../../config"

const ErrorPage = () => {
	return (
		<div className="flex min-h-screen flex-col bg-brand-primary-light">
			<div className="container flex grow flex-col items-center justify-center ">
				<img src={config.assetsPath + "images/error.svg"} alt="" />
				<div className="mt-14 text-center">
					<Typography variant="h1" className="text-brand-light">Link non valido</Typography>
					<Typography variant="h3" className="mt-3 text-greyscale-mid">Verifica sia corretto e scrivici in Live Chat se riscontri ancora il problema.</Typography>
				</div>
			</div>
			<Footer />
		</div>
	)
}

export default ErrorPage