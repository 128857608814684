
import React from "react";
import { Link, LinkProps } from "react-router-dom";
import clsx from "clsx";

import { typographyMap } from "../typography";
import { Typography } from "..";

type LinkVariant = "link" | "link1" | "link2" | "link3" | "link4";

const variantMapping: Record<LinkVariant, keyof typeof typographyMap> = {
	link: "paragraph-p1",
	link1: "paragraph-span1",
	link2: "paragraph-span2",
	link3: "paragraph-span3",
	link4: "paragraph-span4",
};

interface BaseProps {
	variant: "link" | "link1" | "link2" | "link3" | "link4"
	base?: "white" | "black"
}


// Conditial typescript props
type TextLinkProps = BaseProps &
	(
		| LinkProps & {
			isInternal: true;
		}
		| (React.AnchorHTMLAttributes<HTMLAnchorElement> & {
			isInternal: false;
		})
	)

const TypographyLink = ({ base = "white", className, variant = "link", ...restProps }: TextLinkProps) => {
	const typographyVariant = variantMapping[variant];
	const textLinkClasses = clsx("text-brand-dark hover:font-semibold", base === "black" ? "bg-greyscale-dark" : "bg-none");
	if (restProps.isInternal) {
		// Link react-router-dom component for internal links
		const { isInternal, ...rest } = restProps;
		return (
			<Link
				{...rest}
			>
				<Typography
					className={clsx(textLinkClasses, className)}
					variant={typographyVariant}
				>
					{rest.children}
				</Typography>
			</Link>
		);
	}

	const { isInternal, ...rest } = restProps;

	return (
		<a
			className={clsx(textLinkClasses, className)}
			{...rest}
		>
			<Typography
				className={clsx(textLinkClasses, className)}
				variant={typographyVariant}
			>
				{rest.children}
			</Typography>
		</a>
	);
};

export default TypographyLink;
