import { Button, Checkbox, PasswordValidator, TextInput, Typography } from "@frontend-monorepo/design-system";
import { Nullable } from "types/utils";

import NumberInput from "../unimarconi/number-input";
import useSignupForm from "./use-signup-form";
import { signupUnimarconiValidators, signupValidators } from "./validators";

import { FetchRegistrationPlanInfoResponse } from "types/api";

export interface InitialValues {
	firstname: string,
	lastname: string,
	email: string,
	password: string,
	phoneNumber: {
		prefix: string,
		number: string,
	},
	terms: boolean,
	news_and_contact_partner: boolean
}


const defaultInitialValues: InitialValues = {
	firstname: "",
	lastname: "",
	email: "",
	password: "",
	phoneNumber: {
		prefix: "39",
		number: "",
	},
	terms: false,
	news_and_contact_partner: false,
};

interface SignupFormProps {
	isMaster: boolean,
	initialValues?: InitialValues
	token?: Nullable<string>
	planInfo?: Nullable<FetchRegistrationPlanInfoResponse>
}

const masterMktLabel = "Do il consenso a ricevere informazioni sul Master e ad essere messo in contatto con le aziende partner";
const talentMktLabel = "Do il consenso a ricevere informazioni sui nuovi corsi disponibili e ad essere messo in contatto con le aziende partner"

const SignupForm = ({ isMaster, token, planInfo, initialValues = defaultInitialValues }: SignupFormProps) => {
	const { formik, isLoading } = useSignupForm({ isMaster, formInitialValues: initialValues, masterToken: token, planInfo });

	return (
		<form onSubmit={formik.handleSubmit} className="mx-auto max-w-[500px]">

			<div className="flex flex-col gap-6">
				<TextInput
					onChange={formik.handleChange}
					type="text"
					id="firstname"
					name="firstname"
					placeholder="Inserisci Nome"
					isInvalid={!!(formik.errors.firstname && formik.touched.firstname)}
					errorMessage={formik.errors.firstname}
					label="Nome*"
					value={formik.values.firstname}
					disabled={isMaster}
				/>
				<TextInput
					onChange={formik.handleChange}
					type="text"
					id="lastname"
					placeholder="Inserisci Cognome"
					name="lastname"
					isInvalid={!!(formik.errors.lastname && formik.touched.lastname)}
					errorMessage={formik.errors.lastname}
					label="Cognome*"
					value={formik.values.lastname}
					disabled={isMaster}
				/>
				<TextInput
					onChange={formik.handleChange}
					type="email"
					id="email"
					placeholder="Inserisci Email"
					name="email"
					isInvalid={!!(formik.errors.email && formik.touched.email)}
					errorMessage={formik.errors.email}
					label="Email*"
					value={formik.values.email}
					disabled={isMaster}
				/>
				{isMaster && (
					<NumberInput
						onChangePrefix={formik.handleChange}
						onChangeNumber={formik.handleChange}
						prefixId="phoneNumber.prefix"
						prefixName="phoneNumber.prefix"
						numberId="phoneNumber.number"
						numberName="phoneNumber.number"
						isInvalid={!!(
							(formik.errors.phoneNumber?.number && formik.touched.phoneNumber?.number)
							|| (formik.errors.phoneNumber?.prefix && formik.touched.phoneNumber?.prefix))}
						errorMessage={formik.errors.phoneNumber?.number || formik.errors.phoneNumber?.prefix}
						label="Numero di Telefono*"
						prefix={formik.values.phoneNumber.prefix}
						number={formik.values.phoneNumber.number}
					/>
				)}
				<div>
					<TextInput
						onChange={formik.handleChange}
						type="password"
						id="password"
						placeholder="Inserisci Password"
						name="password"
						isInvalid={!!(formik.errors.password && formik.touched.password && formik.errors.password === "Il campo 'password' è obbligatorio")}
						errorMessage={formik.errors.password}
						label="Password*"
						value={formik.values.password}
					/>
					{formik.values.password.length > 0 && (
						<PasswordValidator
							password={formik.values.password}
							validator={isMaster ? signupUnimarconiValidators : signupValidators}
							skipCheckValues={["Il campo 'password' è obbligatorio", "trim-string"]}
						/>
					)}
				</div>

				<div>
					<div className="flex items-center gap-2">
						<Checkbox
							name="terms"
							id="terms"
							onChange={formik.handleChange}
							checked={formik.values.terms}
							label={(
								<>
									Accetto&nbsp;
									<a href="https://www.start2impact.it/termini-e-condizioni/" target="_blank" rel="noreferrer" className="underline">Termini e Condizioni</a>
									&nbsp;e ho letto l&apos;Informativa&nbsp;
									<a href="https://www.start2impact.it/privacy-policy/" target="_blank" rel="noreferrer" className="underline">Privacy</a>
									*
								</>
							)}
						/>
					</div>
					{
						formik.errors.terms && formik.touched.terms &&
						<Typography variant="paragraph-span3" className="mt-2 block text-feedback-error">{formik.errors.terms}</Typography>
					}
					<div className="mt-6 flex gap-2">
						<Checkbox
							checked={formik.values.news_and_contact_partner}
							id="news_and_contact_partner"
							name="news_and_contact_partner"
							label={isMaster ? masterMktLabel : talentMktLabel}
							onChange={formik.handleChange}
						/>
					</div>
					{
						formik.errors.news_and_contact_partner && formik.touched.news_and_contact_partner &&
						<Typography variant="paragraph-span3" className="mt-2 block text-feedback-error">{formik.errors.news_and_contact_partner}</Typography>
					}
				</div>
			</div>

			<div className="flex justify-center">
				<Button as="button" disabled={isLoading} type="submit" className="mt-10 w-fit">continua</Button>
			</div>

		</form>
	);
};

export default SignupForm;
