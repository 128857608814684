const Loader = () => {
	return (
		<div style={{
			display: "flex",
			height: "100vh",
			justifyContent: "center",
			alignItems: "center",
		}}
		>
			{/* <Spinner color={color} /> */}
			<img
				src="https://res.cloudinary.com/start2impact/image/upload/v1679053832/Piattaforma/spinner_wl8ydq.gif"
				style={{
					borderRadius: "100px",
					boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.05)",
				}}
				height="100"
				width="100"
				alt="Spinner"
			/>
		</div>
	);
};

export default Loader;
