import React from "react";

interface DotsProps {
	onClickHandler: (_e: React.MouseEvent<HTMLLIElement> | React.KeyboardEvent<HTMLLIElement>) => void;
	isSelected: boolean;
	index: number;
	label: string;
}

const Dots: React.FC<DotsProps> = ({ onClickHandler, isSelected, index, label }) => {
	if (isSelected) {
		return (
			<li
				className="mr-2 inline-block size-4 rounded-full bg-greyscale-mid last:mr-0"
				aria-label={`Selected: ${label} ${index + 1}`}
				title={`Selected: ${label} ${index + 1}`}
			/>
		);
	}
	return (
		<li
			className="mr-2 inline-block size-4 rounded-full bg-greyscale-extralight last:mr-0"
			onClick={onClickHandler}
			onKeyDown={onClickHandler}
			role="button"
			tabIndex={0}
			title={`${label} ${index + 1}`}
			aria-label={`${label} ${index + 1}`}
		/>
	);
};

export default Dots;
