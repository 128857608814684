
import { useEffect, useState } from "react";
import { faCheck, faXmark } from "@awesome.me/kit-ff2ccff083/icons/classic/solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Typography } from "../typography";

export interface PasswordValidatorProps {
	validator: any
	validateField?: string
	password: string
	skipCheckValues?: string[]
}

const PasswordValidator = ({ validator, password, validateField = "password", skipCheckValues = [] }: PasswordValidatorProps) => {
	const [passwordRequirementsMessage, setPasswordRequirementsMessage] = useState<string[]>([]);
	const [allRequirements, setAllRequirements] = useState<string[]>([]);


	useEffect(() => {
		const requirements = validator.fields[validateField].tests.map((test: any) => test.OPTIONS.message);
		setAllRequirements(requirements);

		validator.validate({ [validateField]: password }, { abortEarly: false })
			.then(() => {
				setPasswordRequirementsMessage([]);
			})
			.catch((errors: any) => {
				const passwordErrors = errors.inner
					.filter((error: any) => error.path === validateField)
					.map((error: any) => error.message);

				setPasswordRequirementsMessage(passwordErrors);
			});
	}, [validator, password, validateField]);

	return (
		password.length > 0 && (
			<div className="mt-4">
				{allRequirements.map((requirement, index) => {
					if (!requirement || skipCheckValues.includes(requirement)) return null;
					return (
						<div key={index} className="mb-1 flex items-center gap-x-2">
							{passwordRequirementsMessage.includes(requirement) ? (
								<FontAwesomeIcon icon={faXmark} className="shrink-0 text-icon-feedback-error" />
							) : (
								<FontAwesomeIcon icon={faCheck} className="text-icon-feedback-success" />
							)}
							<Typography variant="paragraph-span3" className={`text-greyscale-mid opacity-50 ${!passwordRequirementsMessage.includes(requirement) && "line-through"}`}>
								{requirement}
							</Typography>
						</div>
					);
				})}
			</div>
		)
	);
};

export default PasswordValidator;
