/* eslint-disable react/display-name */
import { forwardRef, TextareaHTMLAttributes } from "react";
import clsx from "clsx";

import { Typography } from "../../typography";

/**
 * Props for the TextArea component.
 * @extends TextareaHTMLAttributes<HTMLTextAreaElement>
 */
export interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
	/** Unique identifier for the textarea. */
	id: string;
	/** Name attribute for the textarea. */
	name: string;
	/** Indicates if the textarea is in an invalid state. */
	isInvalid?: boolean;
	/** Error message to display when the textarea is invalid. */
	errorMessage?: string;
	/** Label for the textarea. */
	label?: string;
	/** Indicates if the textarea is in a loading state. */
	isLoading?: boolean;
}

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
	({ id, name, className, isInvalid, errorMessage, label, disabled, isLoading = false, ...restProps }, ref) => {
		return (
			<>
				{label && (
					<label htmlFor={id} className="mb-2 block">
						<Typography variant="paragraph-span3">{label}</Typography>
					</label>
				)}
				<textarea
					ref={ref}
					id={id}
					name={name}
					disabled={disabled || isLoading}
					className={clsx(
						"w-full rounded-lg p-4 text-left text-paragraph-span2-mobile text-greyscale-dark sm:!text-paragraph-span2-desktop",
						"border-0 shadow-input-inner-border placeholder:text-greyscale-mid/50",
						"focus:ring-0 focus:ring-[transparent] focus-visible:shadow-input-inner-focus focus-visible:outline-none",
						"disabled:bg-brand-secondary-light disabled:text-greyscale-mid/50 disabled:shadow-input-inner-disabled",
						className,
					)}
					{...restProps}
				/>

				{isInvalid && <Typography className="text-feedback-error" variant="paragraph-span3">{errorMessage}</Typography>}
			</>

		);
	},
);


export default TextArea;
