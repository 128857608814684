/* eslint-disable react/display-name */
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { faChevronLeft, faChevronRight } from "@awesome.me/kit-ff2ccff083/icons/classic/solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";

import Dots from "./dots.component/index";

const SCROLL_INTERVAL = 2000;

interface CarouselProps extends React.HTMLAttributes<HTMLDivElement> {
	showArrows?: boolean;
	showDots?: boolean;
	autoScroll?: boolean;
	itemsToShow: number;
	arrowRightClassName?: string;
	arrowLeftClassName?: string;
	selectedItemIndex?: number;
	dotsClassName?: string;
	swipeThreshold?: number;
	containerClassName?: string;
}

export interface CarouselRef {
	next: () => void;
	prev: () => void;
	goTo: (_index: number) => void;
}

const Carousel = forwardRef<
	CarouselRef,
	React.PropsWithChildren<CarouselProps>
>(
	(
		{
			children,
			containerClassName,
			dotsClassName,
			showArrows = false,
			showDots = false,
			autoScroll = false,
			itemsToShow,
			arrowLeftClassName,
			arrowRightClassName,
			className,
			selectedItemIndex,
			swipeThreshold = 50
		},
		ref,
	) => {
		const containerRef = useRef<HTMLDivElement>(null);
		const childElements = React.Children.toArray(children);
		const initialIndex =
			typeof selectedItemIndex === "number"
				? Math.max(0, Math.min(selectedItemIndex, childElements.length - 1))
				: 0;

		const [currentIndex, setCurrentIndex] = useState(initialIndex);
		const [containerWidth, setContainerWidth] = useState(0);

		const [touchStartX, setTouchStartX] = useState<number | null>(null);

		const scrollItems = () => {
			setCurrentIndex((prevIndex) => {
				return (prevIndex + 1) % childElements.length;
			});
		};

		const extendedChildElements =
			childElements.length > itemsToShow
				? [...childElements, ...childElements.slice(0, itemsToShow)]
				: childElements;

		const itemWidth = containerWidth / itemsToShow;

		// Functions to navigate through the carousel
		const handlePrev = () => {
			setCurrentIndex((prevIndex) => (prevIndex === 0 ? childElements.length - 1 : prevIndex - 1));
		};

		const handleNext = () => {
			setCurrentIndex((prevIndex) => (prevIndex + 1) % childElements.length);
		};

		// Select item by clicking single dot
		const handleDotClick = (index: number) => {
			setCurrentIndex(index);
		};

		// Expose methods
		useImperativeHandle(ref, () => ({
			next: handleNext,
			prev: handlePrev,
			goTo: (index: number) => {
				if (index >= 0 && index < childElements.length) {
					setCurrentIndex(index);
				}
			},
		}));

		const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
			const touch = e.touches[0];
			setTouchStartX(touch.clientX);
		};

		const handleTouchEnd = (e: React.TouchEvent<HTMLDivElement>) => {
			if (touchStartX === null) return;

			const touch = e.changedTouches[0];
			const deltaX = touch.clientX - touchStartX;

			if (deltaX < -swipeThreshold) {
				// Swipe a sinistra => next
				handleNext();
			}
			else if (deltaX > swipeThreshold) {
				// Swipe a destra => prev
				handlePrev();
			}

			// Reset
			setTouchStartX(null);
		};

		// Calculate item width
		useEffect(() => {
			const updateContainerWidth = () => {
				if (containerRef.current) {
					setContainerWidth(containerRef.current.offsetWidth);
				}
			};

			updateContainerWidth();

			window.addEventListener("resize", updateContainerWidth);
			return () => window.removeEventListener("resize", updateContainerWidth);
		}, []);

		// Auto-scroll
		useEffect(() => {
			if (autoScroll && childElements.length > itemsToShow) {
				const intervalId = setInterval(scrollItems, SCROLL_INTERVAL);
				return () => clearInterval(intervalId);
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [childElements, autoScroll]);

		return (
			<div className={clsx("flex items-center", className)} data-testid="carousel">
				{showArrows && (
					<button
						data-testid="arrow-left"
						disabled={childElements.length <= itemsToShow}
						onClick={handlePrev}
						className={clsx("mr-3 flex size-8 shrink-0 items-center justify-center rounded-full border-2 border-greyscale-extralight bg-greyscale-base", arrowRightClassName)}
						type="button"
						title="Precedente"
					>
						<FontAwesomeIcon icon={faChevronLeft} className="size-3" />
					</button>
				)}
				<div
					className={clsx("relative flex overflow-hidden", containerClassName)}
					ref={containerRef}
					style={{ width: "100%" }}
					onTouchStart={handleTouchStart}
					onTouchEnd={handleTouchEnd}
				>
					<ul
						className="flex transition-transform duration-1000"
						style={{
							transform: `translateX(-${itemWidth * currentIndex}px)`,
							width: itemWidth * extendedChildElements.length,
						}}
					>
						{extendedChildElements.map((child, index) => (
							<li key={index} className="flex min-h-full" style={{ width: itemWidth }}>
								{child}
							</li>
						))}
					</ul>

					{showDots && (
						<div data-testid="dots" className={clsx("absolute bottom-2 left-1/2 -translate-x-1/2", dotsClassName)}>
							<ul className="flex justify-center">
								{childElements.map((_, index) => (
									<Dots
										key={index}
										onClickHandler={() => handleDotClick(index)}
										isSelected={currentIndex === index}
										index={index}
										label="Slide"
									/>
								))}
							</ul>
						</div>
					)}
				</div>
				{showArrows && (
					<button
						data-testid="arrow-right"
						disabled={childElements.length <= itemsToShow}
						onClick={handleNext}
						className={clsx("flex size-8 shrink-0 items-center justify-center rounded-full border-2 border-greyscale-extralight bg-greyscale-base", arrowLeftClassName)}
						type="button"
						title="Successivo"
					>
						<FontAwesomeIcon icon={faChevronRight} className="size-3" />
					</button>
				)}
			</div>
		);
	},
);

export default Carousel;
