import { Helmet } from "react-helmet";
import { Loader, Typography, TypographyLink } from "@frontend-monorepo/design-system";
import usePlanInfo from "hooks/use-plan-info/use-plan-info";

import SignupForm from "../form";

import config from "config";


const Signup = () => {
	const { planInfo, planInfoError, planInfoLoading } = usePlanInfo();

	if (planInfoLoading || planInfoError) return <Loader />;

	return (
		<>
			<Helmet>
				<title>Registrazione | start2impact</title>
			</Helmet>

			<main className="min-h-screen bg-ui-secondary bg-contain bg-bottom bg-no-repeat" style={{ backgroundImage: `url(${config.assetsPath}images/bg-wave-green.svg)` }}>
				<div className="flex h-16 items-center justify-center bg-greyscale-dark">
					<img src="https://res.cloudinary.com/start2impact/image/upload/v1720185442/logo/logowhite.png" alt="" width="220" height="48" />
				</div>

				<section className="mx-auto flex max-w-[600px] flex-col px-4 pb-20 pt-10 sm:pt-20 md:!px-0">
					<h1 className="sr-only">Pagina di registrazione</h1>
					<Typography variant="h2" className="text-center">{planInfo?.registration_title}</Typography>
					<Typography variant="h4" className="mb-10 mt-2 text-center font-medium">Nessun addebito prima della fine della prova!</Typography>

					<SignupForm isMaster={false} planInfo={planInfo} />
					<TypographyLink
						isInternal
						to={{ pathname: config.loginPath }}
						variant="link"
						className="mb-10 mt-5 text-center sm:mb-20"
					>
						Hai già un account? ACCEDI
					</TypographyLink>
				</section>
			</main>
		</>
	);
};

export default Signup;
