import { Button, TextInput, TypographyLink } from "@frontend-monorepo/design-system";

import useLoginForm from "./use-login-form";


const LoginForm = () => {
	const { formik, isPending } = useLoginForm();

	return (
		<form data-testid="login-form" onSubmit={formik.handleSubmit} noValidate className="mt-8 flex flex-col gap-5">
			<TextInput
				onChange={formik.handleChange}
				type="email"
				id="email"
				name="email"
				placeholder="Inserisci la tua email"
				isInvalid={!!(formik.errors.email && formik.touched.email)}
				errorMessage={formik.errors.email}
				label="Email*"
			/>
			<div>
				<TextInput
					onChange={formik.handleChange}
					type="password"
					id="password"
					name="password"
					placeholder="Inserisci la password"
					isInvalid={!!(formik.errors.password && formik.touched.password)}
					errorMessage={formik.errors.password}
					label="Password*"
					showPasswordButton={false}
				/>
				<TypographyLink data-testid="forgot-password" isInternal to={{ pathname: "/password-dimenticata" }} variant="link" className="mt-3">
					Hai dimenticato la password?
				</TypographyLink>
			</div>

			<Button as="button" disabled={isPending} type="submit" className="mx-auto mt-3 w-fit">Accedi</Button>
		</form>
	);
};

export default LoginForm;
