import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Button, TextInput, Typography, TypographyLink, useToast } from "@frontend-monorepo/design-system";
import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";

import { recoveryPswValidator } from "./validators";

import { forgotPassword } from "../../api/mutations";

import config from "../../config";


const ForgotPassword = () => {
	const { showToast } = useToast();
	const { executeRecaptcha } = useGoogleReCaptcha();

	const { mutate: forgotPasswordFn, isPending } = useMutation({
		mutationFn: forgotPassword,
		// eslint-disable-next-line max-len
		onSuccess: () => showToast("Se l'email inserita corrisponde a un account esistente, riceverai un'email con le istruzioni per reimpostare la tua password entro qualche minuto. Il link ha una validità di 3 ore.", "primary"),
		onError: () => showToast("Si è verificato un errore, per favore riprova.", "error"),
	});

	const handleSubmit = async (data: { email: string }) => {
		if (!executeRecaptcha) {
			return;
		}
		const token = await executeRecaptcha("forgotPassword");

		forgotPasswordFn({ email: data.email, recaptcha: token });
	};

	const formik = useFormik({
		initialValues: { email: "" },
		onSubmit: (data: { email: string }) => {
			handleSubmit(data);
		},
		validationSchema: recoveryPswValidator,
	});


	return (
		<div className="min-h-screen bg-ui-secondary">
			<div className="container">

				<div className="flex flex-col items-center pt-20 text-center">
					<img className="mx-auto mb-6" width="64" height="64" alt="Logo di start2impact" src="https://res.cloudinary.com/start2impact/image/upload/h_64/logo/Logo_University-12_wa6eoi.png" />
					<Typography variant="h3" className="mb-8">Recupera la password</Typography>

					<form onSubmit={formik.handleSubmit} className="mt-2 flex w-full max-w-xl flex-col text-left">
						<TextInput
							onChange={formik.handleChange}
							name="email"
							id="email"
							label="Email*"
							placeholder="Inserisci la tua email"
							isInvalid={!!(formik.errors.email && formik.touched.email)}
							errorMessage={formik.errors.email}
						/>
						<Button as="button" disabled={isPending} type="submit" className="mx-auto mt-8 w-fit">Recupera Password</Button>
						<TypographyLink to={{ pathname: config.signupPath }} isInternal variant="link" className="mt-5 text-center">
							Non hai un account? REGISTRATI ORA
						</TypographyLink>
					</form>
				</div>
			</div>
		</div>
	);
};

export default ForgotPassword;
