import { useCallback, useEffect } from "react";
import TagManager from "react-gtm-module";
import { useCustomerly } from "react-live-chat-customerly";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Toast } from "@frontend-monorepo/design-system";
import initSentry from "helpers/sentry";

import Error404 from "features/404";
import ForgotPassword from "features/forgot-password";
import Login from "features/login";
import Logout from "features/logout";
import ResetPassword from "features/reset-password";
import Signup from "features/signup/talent";
import { SignupUnimarconi } from "features/signup/unimarconi";

import config from "config";

const tagManagerArgs = {
	gtmId: import.meta.env.VITE_APP_GOOGLE_TAG_MANAGER,
};


const App = () => {
	const { load } = useCustomerly();

	const loadServices = useCallback(() => {
		TagManager.initialize(tagManagerArgs);
		if (config.isProduction) {
			load({ direction: "right" });
			initSentry();
		}
	}, [load]);

	useEffect(() => {
		loadServices();
	}, [loadServices]);
	return (
		<BrowserRouter>
			<Routes>
				<Route path={`${config.basepath}`} element={<Login />} />
				<Route path={`${config.loginPath}`} element={<Login />} />
				<Route path={`${config.signupPath}`} element={<Signup />} />
				<Route path={`${config.signupPath}-master`} element={<SignupUnimarconi />} />
				<Route path={`${config.basepath}password-dimenticata`} element={<ForgotPassword />} />
				<Route path={`${config.basepath}password-dimenticata/nuova-password`} element={<ResetPassword />} />
				<Route path={`${config.logoutPath}`} element={<Logout />} />
				<Route path="*" element={<Error404 />} />
			</Routes>
			<Toast duration={5000} />
		</BrowserRouter>
	);
};

export default App;
