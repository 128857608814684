import { memo, ReactNode, useLayoutEffect, useState } from "react";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";

/**
 * Each segment in multi-segment mode.
 * - progress: 0..100 indicates HOW MUCH of that slice to fill.
 * - color: color of this segment (if absent, uses defaultSegmentColor).
 */
interface Segment {
	progress: number;
	color?: string;
}

interface CircularProgressProps {
	/**
	 * SINGLE CASE:
	 *   - If segments is not present or empty/1, "progress" is used, and a single circle (0..100) is drawn.
	 */
	progress?: number;

	/**
	 * MULTI-SEGMENT CASE:
	 *   - If segments.length >= 2, the circle is divided into N equal slices (N=segments.length).
	 *   - Each slice has a gap of gapDeg.
	 *   - Each can be filled based on "segment.progress".
	 */
	segments?: Segment[] | null;

	/** Icon (FontAwesome) to display in the center. */
	icon?: IconDefinition;
	content?: ReactNode;
	iconClassName?: string;
	contentClassName?: string;

	/** Default color (used in single mode). */
	progressColor?: string;
	/** Default color for segments without a color. */
	defaultSegmentColor?: string;

	/** Total size of the circle (px). */
	size?: number;

	/**
	 * Gap in degrees between slices.
	 * If N=3 and gapDeg=5, each slice is 360/3=120°, the actual arc is 120-5=115°,
	 * and 5° remain "empty" at the end of each slice.
	 */
	gapDeg?: number;
}

const CircularProgress = ({
	content,
	progress = 0,
	segments,
	icon,
	iconClassName,
	progressColor = "#79C23E",
	defaultSegmentColor = "#79C23E",
	size = 96,
	gapDeg = 2,
}: CircularProgressProps) => {
	const [animationFactor, setAnimationFactor] = useState(0);
	// Check if we have multiple segments
	const isMultiArc = segments && segments.length >= 2;

	useLayoutEffect(() => {
		setAnimationFactor(0);
		const duration = 600;
		const startTime = performance.now();

		let frameId: number;

		function animate(t: number) {
			const elapsed = t - startTime;
			const ratio = Math.min(elapsed / duration, 1);
			setAnimationFactor(ratio);
			if (ratio < 1) {
				frameId = requestAnimationFrame(animate);
			}
		}

		frameId = requestAnimationFrame(animate);

		return () => {
			// cleanup
			if (frameId) cancelAnimationFrame(frameId);
		};
	}, [progress, gapDeg]);

	// Base calculations for the SVG
	const strokeWidth = 8;
	const radius = (size - strokeWidth) / 2;
	const circumference = 2 * Math.PI * radius;

	// ---------------------------------
	// 1) SINGLE ARC MODE
	// ---------------------------------
	if (!isMultiArc) {
		const animatedProgress = progress * animationFactor;
		const offset = circumference - (animatedProgress / 100) * circumference;

		return (
			<div className="relative flex items-center justify-center" style={{ width: size, height: size }}>
				<svg className="absolute left-0 top-0" width={size} height={size}>
					{/* Gray background */}
					<circle
						className="text-[#edf1f4]"
						stroke="currentColor"
						strokeWidth={strokeWidth}
						fill="transparent"
						r={radius}
						cx={size / 2}
						cy={size / 2}
						transform={`rotate(-90 ${size / 2} ${size / 2})`} // start at the top
					/>
					{/* Main arc */}
					<circle
						className="transition-all duration-300 ease-out"
						style={{ color: progressColor }}
						stroke="currentColor"
						strokeWidth={strokeWidth}
						strokeDasharray={circumference}
						strokeDashoffset={offset}
						fill="transparent"
						r={radius}
						cx={size / 2}
						cy={size / 2}
						transform={`rotate(-90 ${size / 2} ${size / 2})`}
					/>
				</svg>

				{icon && <FontAwesomeIcon
					icon={icon}
					className={clsx("text-icon-greyscale-mid", iconClassName)}
				/>}

				{content}
			</div>
		);
	}

	// ---------------------------------------------------------
	// 2) MULTI-SEGMENT ARC MODE
	//    - Divide the circle into N equal slices = 360/N
	//    - Inside each slice, there is an "arc" max = (360/N - gapDeg)
	//    - Each fills its part based on segment.progress (0..100)
	// ---------------------------------------------------------
	const N = segments.length;

	// Each slice = 360 / N
	const sliceAngle = 360 / N;
	// Of these, 'arcAngle' is the "filled" part; 'gapDeg' remains empty
	const arcAngle = sliceAngle - gapDeg;
	// If arcAngle is negative (gap too large), force it to 0.
	const safeArcAngle = Math.max(arcAngle, 0);

	// Array of <circle> for each segment
	const arcs = segments.map((seg, i) => {
		// "Percentage" of its slice (not the entire circle).
		// If seg.progress=100 => fills the entire slice.
		// If seg.progress=50 => fills half of the slice, etc.
		const fillAngle = (seg.progress / 100) * safeArcAngle;

		// Animation
		const animatedAngle = fillAngle * animationFactor;
		const arcLength = (animatedAngle / 360) * circumference;
		const offset = circumference - arcLength;

		// Each slice starts at i*sliceAngle. Then -90 to start at the top.
		const startAngle = i * sliceAngle;
		const rotate = -90 + startAngle;

		return (
			<circle
				key={i}
				className="transition-all duration-300 ease-out"
				style={{ color: seg.color || defaultSegmentColor }}
				stroke="currentColor"
				strokeWidth={strokeWidth}
				strokeDasharray={circumference}
				strokeDashoffset={offset}
				fill="transparent"
				r={radius}
				cx={size / 2}
				cy={size / 2}
				transform={`rotate(${rotate} ${size / 2} ${size / 2})`}
			/>
		);
	});

	return (
		<div className="relative flex items-center justify-center" style={{ width: size, height: size }}>
			<svg className="absolute left-0 top-0" width={size} height={size}>
				<circle
					className="text-[#edf1f4]"
					stroke="currentColor"
					strokeWidth={strokeWidth}
					fill="transparent"
					r={radius}
					cx={size / 2}
					cy={size / 2}
					transform={`rotate(-90 ${size / 2} ${size / 2})`}
				/>
				{arcs}
			</svg>

			{icon && <FontAwesomeIcon
				icon={icon}
				className={clsx("text-icon-greyscale-mid", iconClassName)}
			/>}
		</div>
	);
}

export default memo(CircularProgress);
