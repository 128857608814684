const config = {
	isProduction: import.meta.env.PROD,
	apiUrl: import.meta.env.VITE_APP_API_URL,
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	reCaptchaKey: import.meta.env.VITE_APP_GOOGLE_RECAPTCHA_SITE_KEY!,
	appUrl: import.meta.env.VITE_APP_URL,
	talentUrl: import.meta.env.VITE_APP_TALENT_URL,
	adminUrl: import.meta.env.VITE_APP_ADMIN_URL,
	coachUrl: import.meta.env.VITE_APP_COACH_URL,
	masterUrl: import.meta.env.VITE_APP_MASTER_URL,
	assetsPath: import.meta.env.VITE_BASE_URL,
	loginUrl: import.meta.env.VITE_APP_LOGIN_URL,
	cookieName: "iub_pref",
	logoutPath: "/logout",
	loginPath: "/login",
	basepath: "/",
	onboardingPath: "/onboarding/master",
	signupPath: "/signup",
	cloudinaryFetchPath: "https://res.cloudinary.com/start2impact/image/fetch/",
	cookieExpirationDays: 30,
};


export default config;
