import { http } from "@frontend-monorepo/helpers";

import {
	ForgotPasswordParams,
	LoginDataParams,
	LoginResponse,
	ResetPasswordParams,
	SignupDataParams,
	SignupMasterUnimarconiParams,
	SignupMasterUnimarconiResponse,
	SignupResponse
} from "../../types/api";

import config from "../../config";


// end types declaration

const login = async (loginData: LoginDataParams): Promise<LoginResponse> => {
	const response = await http(config.cookieName, config.loginUrl, false)
		.post(`${config.apiUrl}/v2/auth/login`, loginData);

	return response.data;
};

const signup = async (signupData: SignupDataParams): Promise<SignupResponse> => {
	const { recaptcha, ...userData } = signupData;

	const { data } = await http(config.cookieName, config.loginUrl, false)
		.post(`${config.apiUrl}/public/users/registration`, userData, { headers: { "X-Recaptcha-Token": recaptcha } });

	return data.entities;
};

const forgotPassword = async ({ email, recaptcha }: ForgotPasswordParams) => {
	const { data } = await http(config.cookieName, config.loginUrl, false)
		.post(`${config.apiUrl}/public/users/forgot-password`, { email }, { headers: { "X-Recaptcha-Token": recaptcha } });

	return data;
};

const resetPassword = async ({ token, new_password, recaptcha }: ResetPasswordParams) => {
	const { data } = await http(config.cookieName, config.loginUrl, false)
		.put(`${config.apiUrl}/public/users/reset-password`, { token, new_password }, { headers: { "X-Recaptcha-Token": recaptcha } });

	return data.entities;
};

const signupMasterUnimarconi = async (signupData: SignupMasterUnimarconiParams): Promise<SignupMasterUnimarconiResponse> => {
	const { data } = await http(config.cookieName, config.loginUrl, false).post(`${config.apiUrl}/v2/auth/master/activate-account`, signupData);

	return data
}

export { login, signup, forgotPassword, resetPassword, signupMasterUnimarconi };
