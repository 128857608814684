import { useEffect, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useLocation } from "react-router-dom";
import { useToast } from "@frontend-monorepo/design-system";
import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import Cookies from "js-cookie";
import { Nullable } from "types/utils";

import { InitialValues } from "./form";
import { signupUnimarconiValidators, signupValidators } from "./validators";

import { signup, signupMasterUnimarconi } from "api/mutations";
import {
	FetchRegistrationPlanInfoResponse,
	SignupMasterUnimarconiParams,
	SignupMasterUnimarconiResponse,
	SignupMasterUnimarconiResponseError
} from "types/api";

import config from "config";


interface UseSignupFormProps {
	isMaster: boolean,
	formInitialValues: InitialValues
	masterToken?: Nullable<string>
	planInfo?: Nullable<FetchRegistrationPlanInfoResponse>
}

const useSignupForm = ({ isMaster, formInitialValues, masterToken, planInfo }: UseSignupFormProps) => {
	const { search } = useLocation();
	const { executeRecaptcha } = useGoogleReCaptcha();
	const { showToast } = useToast();
	const [friendToken, setFriendToken] = useState("");
	const [currentPlanSlug, setCurrentPlanSlug] = useState("");
	const [isLoading, setIsLoading] = useState(false);


	const {
		mutate: signupUnimarconiFn,
	} = useMutation<SignupMasterUnimarconiResponse, SignupMasterUnimarconiResponseError, SignupMasterUnimarconiParams>({
		mutationFn: signupMasterUnimarconi,
		onSuccess: (data) => {
			const domain = window.location.host.includes("localhost") ? "unimarconi.localhost" : ".start2impact.it";
			Cookies.set(config.cookieName, data.accessToken, { expires: config.cookieExpirationDays, domain });
			window.location.href = config.masterUrl;
		},
		onError: (error) => {
			if (isLoading) setIsLoading(false)

			if (error.response.data.message.includes("phone must be a valid phone number")) {
				showToast("Il numero di telefono non è valido", "error")
			}
			else {
				showToast("La registrazione non è andata a buon fine. Controlla che l'email non sia già in uso.", "error")
			}
		},
	});

	const {
		mutate: signupFn,
	} = useMutation({
		mutationFn: signup,
		onSuccess: (data) => {
			const domain = window.location.host.includes("localhost") ? "staging.localhost" : ".start2impact.it";
			Cookies.set(config.cookieName, data.token, { expires: config.cookieExpirationDays, domain });
			window.location.href = planInfo?.slug ? `/checkout?plan=${planInfo.slug}` : "/checkout";
		},
		onError: (err) => {
			if (isLoading) setIsLoading(false)
			showToast("La registrazione non è andata a buon fine. Controlla che l'email non sia già in uso.", "error")
		}
	});

	const handleSignupUnimarconi = async (data: InitialValues) => {
		const signupData = {
			token: masterToken || "",
			phone: "+" + data.phoneNumber.prefix.trim() + data.phoneNumber.number.trim(),
			password: data.password,
			news_and_contact_partner: data.news_and_contact_partner
		}

		signupUnimarconiFn(signupData);
	}


	const handleSignup = async (data: InitialValues) => {
		if (!executeRecaptcha) {
			showToast("La registrazione non è andata a buon fine. Controlla che l'email non sia già in uso.", "error");
			return;
		}

		const token = await executeRecaptcha("signup");
		const referrersId = Cookies.get("referrers");

		const signupData = {
			guest_identifier: referrersId || null,
			email: data.email,
			firstname: data.firstname,
			lastname: data.lastname,
			password: data.password,
			news_and_contact_partner: data.news_and_contact_partner ? 1 : 0,
			friend_token: friendToken ?? undefined,
			plan_slug: currentPlanSlug,
			recaptcha: token,
		};

		signupFn(signupData);
	};

	const formik = useFormik({
		initialValues: formInitialValues,
		onSubmit: (data) => {
			setIsLoading(true);
			if (isMaster) return handleSignupUnimarconi(data);
			return handleSignup(data);
		},
		validationSchema: isMaster ? signupUnimarconiValidators : signupValidators,
		enableReinitialize: true,
	});

	useEffect(() => {
		if (!isMaster) {
			const searchParams = new URLSearchParams(search);
			const queryParamsSlug = searchParams.get("plan");
			const queryParamsFriendReferal = searchParams.get("token");

			if (queryParamsSlug) setCurrentPlanSlug(queryParamsSlug);
			if (queryParamsFriendReferal) setFriendToken(queryParamsFriendReferal);
		}
	}, [currentPlanSlug, search, isMaster]);


	return { formik, isLoading };
};

export default useSignupForm;
