import { useCallback, useEffect, useState } from "react";
import { faCheckCircle, faExclamationCircle, faExclamationTriangle, faInfoCircle } from "@awesome.me/kit-ff2ccff083/icons/classic/solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";

import { useToast } from "../../context/toast-provider";
import { Typography } from "../typography";

import "./style.css";

const toastTypes =
{
	primary: {
		id: 1,
		icon: faInfoCircle,
		borderColor: "border-b-brand-primary",
		iconColor: "text-icon-brand-mid",
	},
	info: {
		id: 2,
		icon: faInfoCircle,
		borderColor: "border-b-feedback-info",
		iconColor: "text-icon-feedback-info",
	},
	error: {
		id: 3,
		icon: faExclamationCircle,
		borderColor: "border-b-feedback-error",
		iconColor: "text-icon-feedback-error",
	},
	warning: {
		id: 4,
		icon: faExclamationTriangle,
		borderColor: "border-b-feedback-warning",
		iconColor: "text-icon-feedback-warning",
	},
	waiting: {
		id: 5,
		icon: faExclamationCircle,
		borderColor: "border-b-feedback-waiting",
		iconColor: "text-icon-feedback-waiting",
	},
	success: {
		id: 6,
		icon: faCheckCircle,
		borderColor: "border-b-feedback-success",
		iconColor: "text-icon-feedback-success",
	},
};

interface ToastProps {
	size?: "md" | "lg";
	slideFrom?: "top" | "right";
	duration?: number;
}


const Toast = ({ size = "md", slideFrom = "right", duration = 3000 }: ToastProps) => {
	const { toast, hideToast } = useToast();
	const toastType = toast?.type ? toastTypes[toast.type] : toastTypes.primary;
	const [exiting, setExiting] = useState(false);

	const slideIn = slideFrom === "right" ? "animate-slide-in-right" : "animate-slide-in-down";
	const slideOut = slideFrom === "right" ? "animate-slide-out-right" : "animate-slide-out-up";

	const closeToast = useCallback(() => {
		setExiting(true);
		setTimeout(() => {
			hideToast();
			setExiting(false);
		}, 500);
	}, [hideToast]);


	useEffect(() => {
		if (toast) {
			const timer = setTimeout(closeToast, duration);

			return () => {
				clearTimeout(timer);
			};
		}
	}, [toast, closeToast, duration]);

	const { icon } = toastType;

	if (!toast) return null;

	return (
		<div className={
			clsx(
				"fixed top-0 z-[9999] w-full max-w-[666px] space-y-2 p-6 sm:w-auto sm:min-w-[360px]",
				slideFrom === "right" ? "right-0" : "left-1/2 -translate-x-1/2",
			)
		}
		>
			<button
				type="button"
				key={toast.id}
				onClick={closeToast}
				className={clsx(
					"flex w-full items-center rounded border-b-8 bg-greyscale-base text-left shadow",
					size === "md" ? "p-4" : "p-6",
					exiting ? slideOut : slideIn,
					toastType.borderColor,
				)}
			>
				<FontAwesomeIcon icon={icon} className={clsx(toastType.iconColor, "mr-6 shrink-0", size === "lg" ? "size-8" : "size-6")} />

				<Typography variant={size === "lg" ? "label" : "label-1"}>{toast.label}</Typography>
			</button>
		</div>
	);
};

export default Toast;
