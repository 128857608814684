import { faPlus } from "@awesome.me/kit-ff2ccff083/icons/classic/regular";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@frontend-monorepo/design-system"


export interface NumberInputProps {
	/** Prefix input id */
	prefixId: string;
	/** Prefix input name */
	prefixName: string;
	/** Number input id */
	numberId: string;
	/** Number input name */
	numberName: string;
	/** Indicates if the input is in an invalid state */
	isInvalid?: boolean;
	/** The error message to display when the input is invalid */
	errorMessage?: string;
	/** The label for the input element */
	label?: string;
	/** The prefix for the number */
	prefix?: string;
	/** The number value */
	number?: string;
	/** Callback to handle prefix input change */
	onChangePrefix?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	/** Callback to handle number input change */
	onChangeNumber?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}


const NumberInput = ({
	prefixId,
	prefixName,
	numberId,
	numberName,
	isInvalid,
	errorMessage,
	label,
	number,
	prefix,
	onChangePrefix,
	onChangeNumber
}: NumberInputProps) => {

	return (
		<div>
			<label htmlFor={numberId}>
				<Typography variant="paragraph-span3">
					{label}
				</Typography>
			</label>

			<label htmlFor={prefixId} className="sr-only">Prefisso Internazionale numero mobile</label>

			<div className="mt-2 flex">
				<div className="relative">
					<input
						id={prefixId}
						name={prefixName}
						type="tel"
						className="input w-[80px] rounded-r-none pl-8 shadow-[inset_0_2px_0_0_#F1F5F4,inset_2px_0_0_0_#F1F5F4,inset_0_-2px_0_0_#F1F5F4,inset_-1px_0_0_0_#F1F5F4]"

						onChange={onChangePrefix}
						value={prefix}
						maxLength={3}
					/>
					<FontAwesomeIcon icon={faPlus} className="absolute left-4 top-1/2 size-2 -translate-y-1/2 text-icon-greyscale-mid" />
				</div>
				<input
					id={numberId}
					name={numberName}
					type="tel"
					className="input rounded-l-none shadow-[inset_0_2px_0_0_#F1F5F4,inset_-2px_0_0_0_#F1F5F4,inset_0_-2px_0_0_#F1F5F4,inset_1px_0_0_0_#F1F5F4]"
					placeholder="Inserisci Numero di Telefono"
					onChange={onChangeNumber}
					value={number}
				/>
			</div>

			{isInvalid && (
				<Typography variant="paragraph-span3" className="mt-2 text-feedback-error">
					{errorMessage}
				</Typography>
			)}
		</div>
	)
}

export default NumberInput