
import React, { ReactNode } from "react";
import { Link, LinkProps } from "react-router-dom";
import clsx from "clsx";

type BaseProps = {
	variant?: "primary" | "secondary" | "tertiary";
	size?: "sm" | "md" | "lg";
	children: ReactNode;
	disabled?: boolean;
	icon?: ReactNode;
};

// Conditial typescript props
type ButtonProps = BaseProps &
	(
		| (React.ButtonHTMLAttributes<HTMLButtonElement> & {
			as: "button";
		})
		| LinkProps & {
			as: "internal-link";
		}
		| (React.AnchorHTMLAttributes<HTMLAnchorElement> & {
			as: "external-link";
		})
	)


// Style variants
const variantClasses = {
	primary: "border-none bg-accent-mid focus:!bg-accent-dark hover:!bg-accent-dark text-grey-100 ",
	secondary: "border border-solid border-accent-dark bg-accent-base text-accent-dark " +
		"hover:border-greyscale-dark hover:text-greyscale-dark hover:bg-accent-base focus:border-greyscale-dark focus:text-greyscale-dark",
	tertiary: " border-none bg-brand-primary focus:!bg-brand-secondary hover:!bg-brand-secondary text-grey-100",
};

const sizeClasses = {
	sm: "py-1 px-2 text-button-4-mobile sm:text-button-4-desktop",
	md: "py-2 px-4 text-button-3-mobile sm:text-button-3-desktop",
	lg: "py-4 px-8 text-button-2-mobile sm:text-button-2-desktop",
};

/** Primary UI component for user interaction */
const Button = ({ className, disabled, size = "lg", variant = "primary", icon: Icon, ...restProps }: ButtonProps) => {
	const variantClass = variantClasses[variant];
	const sizeClass = sizeClasses[size];
	const buttonClasses = clsx(
		"flex cursor-pointer items-center justify-center rounded font-paragraph font-semibold uppercase",
		disabled && "pointer-events-none opacity-50",
		variantClass,
		sizeClass,
		className,
	);

	if (restProps.as === "external-link") {
		// Href for external links
		const { as, ...rest } = restProps;
		return (
			<a
				className={buttonClasses}
				{...rest}
			>
				{Icon && <span className={clsx("flex items-center", size === "sm" ? "!text-[0.625rem]" : "!text-[1rem]")}>{Icon}</span>}
				{rest.children}
			</a>
		);
	}

	if (restProps.as === "internal-link") {
		// Link react-router-dom component for internal links
		const { as, ...rest } = restProps;
		return (
			<Link
				className={buttonClasses}
				{...rest}
			>
				{Icon && <span className={clsx("flex items-center", size === "sm" ? "!text-[0.625rem]" : "!text-[1rem]")}>{Icon}</span>}
				{rest.children}
			</Link>
		);
	}

	const { as, ...rest } = restProps;
	return (
		<button
			disabled={disabled}
			type="button"
			className={buttonClasses}
			{...rest}
		>
			{Icon && <span className={clsx("flex items-center", size === "sm" ? "!text-[0.625rem]" : "!text-[1rem]")}>{Icon}</span>}
			{rest.children}
		</button>
	);
};

export default Button;
