import { useToast } from "@frontend-monorepo/design-system";
import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import Cookies from "js-cookie";

import redirectByRoleOnLogin from "../../../helpers/redirectByRole";
import { loginValidators } from "../validators";

import { login } from "../../../api/mutations";

import config from "../../../config";


interface FormInitialValues {
	email: string;
	password: string;
}


const formInitialValues: FormInitialValues = { email: "", password: "" };

const useLoginForm = () => {
	const { showToast } = useToast();

	const {
		isPending,
		mutate: loginFn,
	} = useMutation({
		mutationFn: login,
		onSuccess: (data) => {
			const domain = window.location.host.includes("localhost") ? "localhost" : ".start2impact.it";
			Cookies.set(config.cookieName, data.accessToken, { expires: config.cookieExpirationDays, domain });
			redirectByRoleOnLogin(config.cookieName, data.loginProperties || []);
		},
		onError: () => showToast("Credenziali Email o password non valide.", "error"),
	});

	const handleLogin = async (data: FormInitialValues) => {
		const referrersId = Cookies.get("referrers");

		const loginData = { ...data, guest_identifier: referrersId || null };
		loginFn(loginData);
	};

	const formik = useFormik({
		initialValues: formInitialValues,
		onSubmit: (data) => {
			handleLogin(data);
		},
		validationSchema: loginValidators,
	});

	return { formik, isPending };
};

export default useLoginForm;
